const config = {
    apiKey: "AIzaSyBqKnwSrYMbSVR9WScLiqw-sq_hRZjGvaM",
    authDomain: "iu-portal.firebaseapp.com",
    projectId: "iu-portal",
    storageBucket: "iu-portal.appspot.com",
    messagingSenderId: "1049208957781",
    appId: "1:1049208957781:web:f381d60c81b1ec1e965ddc",
    measurementId: "G-M7M6E3H7YM"
};

export default config;
